import React from 'react';
import './App.scss';
import Hero from './components/Hero';

const App = () => {
  return (
    <div className="App">
      <section>
        <Hero />
      </section>
      {/* <section>Employment</section>
      <section>Hobby</section>
      <section>Personal Projects</section> */}
    </div>
  );
}

export default App;
