import "../css/Hero.scss";
import { Variants, motion } from "framer-motion";

const textVariants: Variants = {
  initial: {
    x: -500,
    opacity: 0,
    zIndex: 1,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    }
  }
}

const sliderVariants: Variants = {
  initial: {
    x: 0,
  },
  animate: {
    x: "-550%",
    transition: {
      repeat: Infinity,
      repeatType: "mirror",
      duration: 40,
    }
  }
}

const Hero = () => {
  return (
    <div className="hero">
      <div className="wrapper">
        <motion.div className="textContainer" variants={textVariants} initial="initial" animate="animate">
          <motion.h2 variants={textVariants}>Ariel Evangelista</motion.h2>
          <motion.h1 variants={textVariants}>Systems Developer</motion.h1>
        </motion.div>
      </div>
      <motion.div className="slidingTextContainer" variants={sliderVariants} initial="initial" animate="animate">
        Software Developer PC Enthusiast
      </motion.div>
      <div className="imageContainer">
        <img src="/ariel.png" alt="" />
      </div>
    </div>
  )
}

export default Hero;